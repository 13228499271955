import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styled from "styled-components";
import theme from "../../theme/theme.yaml";

const List = (props) => {
  const { edges } = props;

  return (
    <StyledFragment>
      <ul>
        {edges.map((edge) => {
          const {
            node: {
              frontmatter: { title },
              fields: { slug },
            },
          } = edge;

          return (
            <li key={slug}>
              <Link to={slug}>{title}</Link>
            </li>
          );
        })}
      </ul>
    </StyledFragment>
  );
};

List.propTypes = {
  edges: PropTypes.array.isRequired,
  // theme: PropTypes.object.isRequired,
};

export default List;

const StyledFragment = styled.div`
  ul {
    margin: ${theme.space.stack.m};
    padding: ${theme.space.m};
    list-style: circle;
  }
  li {
    padding: ${theme.space.xs} 0;
    font-size: ${theme.font.size.s};
    line-height: ${theme.font.lineHeight.l};
  }
`;
