import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "../../theme/theme.yaml";

const StyledFragment = styled.div`
    .article {
      padding: ${theme.space.inset.default};
      margin: 0 auto;
      font-size: ${theme.font.size.m};
      /* line-height: ${theme.font.lineHeight.m}; */
    }
    h1 {
      margin-top:
      margin-bottom:2rem;
    }

    h2,
    h3 {
      margin-top: 1.5rem;
      margin-bottom:2rem;
    }

    p {
      margin-bottom: 1.5rem
    }
    /* p {
      font-size: ${theme.font.size.m}
    } */

    img {
      margin-bottom: 10px;
    }
    /* @from-width tablet {
      .article {
        padding: ${`calc(${theme.space.default}) calc(${theme.space.default} * 2)`};
        max-width: ${theme.text.maxWidth.tablet};
      }
    } */
    @media screen and (min-width: 700px) {
      .article {
        padding: ${`calc(${theme.space.default} * 2) 0 calc(${theme.space.default} * 2)`};
        max-width: ${theme.text.maxWidth.desktop};
      }
    }
  `;

const Article = (props) => {
  // const { children, theme } = props;
  const { children } = props;

  return (
    <StyledFragment>
      <article className="article">{children}</article>

      {/* --- STYLES --- */}
      {/* <style jsx>{`
        .article {
          padding: ${theme.space.inset.default};
          margin: 0 auto;
        }
        @from-width tablet {
          .article {
            padding: ${`calc(${theme.space.default}) calc(${theme.space.default} * 2)`};
            max-width: ${theme.text.maxWidth.tablet};
          }
        }
        @from-width desktop {
          .article {
            padding: ${`calc(${theme.space.default} * 2 + 90px) 0 calc(${theme.space.default} * 2)`};
            max-width: ${theme.text.maxWidth.desktop};
          }
        }
      `}</style> */}
    </StyledFragment>
  );
};

Article.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
};

export default Article;
